import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {getImageUrl} from './randomreid.js';
import {checkSession, loginUser} from './login.js';

class LoginPrompt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {username: '', password: ''};
        this.handleChange = this.handleChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onSuccess = props.onSuccess;
    }

    handleChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({[name]: value});
    }
    
    render() {
        return (
            <div className="login-prompt">
              <form>
                <div className="login-input">
                  <label htmlFor="username">username
                    <input
                      name="username"
                      value={this.state.username}
                      onChange={this.handleChange}
                      type="username"/><br/>
                  </label>
                </div>
                <div className="login-input">
                  <label htmlFor="password">password
                    <input
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      type="password"/><br/>
                  </label>
                </div>
                <div className="login-input">
                  <input value="submit" type="button" onClick={this.onClick}/><br/>
                </div>
              </form>
            </div>
        );
    }

    onClick() {
        loginUser(this.state.username, this.state.password, this.onSuccess);
    }
}

class Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {url: props.url};
    }

    render() {
        return(
            <img src={this.state.url} alt='' className='reid-image'/>
        );
    }
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
        this.handleImageFailure = this.handleImageFailure.bind(this);
        console.log("1STATE: checking_session");
        this.state = {
            value: 'checking_session'
        };
    }

    componentDidMount() {
        if (!checkSession(value => {
            console.log('2STATE: ' + (value ? 'logged_in' : 'logged_out'));
            this.setState({ 
                value: value ? 'logged_in' : 'logged_out'
            });
            if (value) {
                this.updateImageUrl();
            }
        })) {
            console.log("STATE: logged_out");
            this.setState({
                value: 'logged_out'
            });
        }
    }

    updateImageUrl() {
        getImageUrl(url => {
            this.setState(state => {
                return {
                    value: 'logged_in',
                    url: url
                };
            });
        }, this.handleImageFailure);
    }

    handleLoginSuccess() {
        console.log("3STATE: logged_in");
        this.setState({value: 'logged_in'});
        this.updateImageUrl();
    }

    handleImageFailure() {
        console.log("4STATE: logged_out");
	this.setState(state => {return {value: 'logged_out'};});
    }
    
    render() {
        if (this.state.value === 'logged_in' && this.state.url) {
            return(
                <Image url={this.state.url}/>
            );
        } else if (this.state.value === 'logged_out') {
            return(
                <LoginPrompt
                  onSuccess={this.handleLoginSuccess}
                />
            );
        } else {
            return null;
        }            
    }
}

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
