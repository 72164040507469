import {getToken} from './login.js'

export function getImageUrl(onSuccess, onFailure) {
    var xhttp = new XMLHttpRequest();
    xhttp.open("GET", `https://${process.env.REACT_APP_API_DOMAIN}/random-reid`, true);
    xhttp.setRequestHeader("Authorization", getToken());
    xhttp.send();
    xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            var response = JSON.parse(this.responseText);
            console.log("Setting image ", response.key);
            onSuccess(response.url);
        } else if(this.status !== 200) {
	    onFailure();
	}
    }
}
